import React from "react"
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"



const CarrousselWrapper = () => {

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativeDirectory: { eq: "slides" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid (quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    `)

  return (
    <Carousel
      showThumbs={false}
      autoPlay
      infiniteLoop
      emulateTouch
      useKeyboardArrows
      showArrows
      transitionTime={500}
    >
      {
        data.allFile.edges.map(image => (
          <Img
            fluid={image.node.childImageSharp.fluid}
            alt=""
            key={image.node.base.split(".")[0]}
          />
        ))
      }
    </Carousel>
  )
}

export default CarrousselWrapper
