import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import HourIcon from "../assets/icons/hour.svg"
import LocationIcon from "../assets/icons/location.svg"

import indexStyles from "./index.module.css"
import MapWrapper from "../components/mapWrapper"
import Welcome from "../components/welcome"
import Image from "../components/image"



const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      desktop: file(relativePath: { eq: "bubbles.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

    }
  `)

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <Layout>
      <SEO title="Station de lavage auto" />
      <BackgroundImage
        Tag="div"
        fluid={imageData}
      >
        <section id="accueil" className={indexStyles.accueil}>
          <Welcome />
        </section>
      </BackgroundImage>


      <section id="formules" className={indexStyles.section1}>
        <div className={indexStyles.title}>
          <h2>Nos formules</h2>
        </div>
        <p className={indexStyles.formule}>
          <strong>{data.site.siteMetadata.title}</strong> votre station de
          lavage all inclusive vous propose 3 formules&nbsp;:
        </p>
        <div className={indexStyles.offers}>
          <div data-aos="flip-up">
            <Card
              title="Armoire de lavage haute pression"
              desc={
                <p>
                  Incluant programme brosse, jantes, cire liquide (eau chaude)
                </p>
              }
              icon="cleanner"
            />
          </div>

          <div data-aos="flip-up">

            <Card
              title="Lavage à la demande à partir de 18€"
              desc={
                <>
                  <p>Lavage extérieur à partir de 18€</p>
                  <p>Lavage intérieur à partir de 20€ </p>
                  <p>Lavage complet forfait à partir de 35€</p>
                </>
              }
              icon="hand"
            />
          </div>

          <div data-aos="flip-up">

            <Card
              title="Location box à l'heure pour véhicules grands cabari"
              desc={
                <p>
                  Location espace de lavage avec Karcher pour utilitaires, SUV,
                  4/4 etc
                </p>
              }
              icon="utility"
            />
          </div>
        </div>
        <hr />
        <p
          className={indexStyles.paymentType}
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <strong>Paiement en espèces ou carte bancaire</strong>
        </p>
      </section>

      <section id="horaires" className={indexStyles.section2}>
        <div className={indexStyles.inner}>
          <div className={indexStyles.title}>
            <HourIcon className={indexStyles.icon} />
            <h2>Nos horaires d'ouverture</h2>
          </div>
          <p>
            Nous sommes ouvert 7 jours / 7 de <strong>6h à 22h</strong>
          </p>
          <div className={indexStyles.hoursImg} data-aos="fade-right"><Image name="hoursPhoto" alt="" /></div>

        </div>
      </section>

      <section id="itineraire" className={indexStyles.section3}>
        <div className={indexStyles.inner}>
          <div className={indexStyles.title}>
            <LocationIcon className={indexStyles.icon} />
            <h2>Où nous trouver</h2>
          </div>
          <div>
            <p>Quartier Propreté, 97260 Morne&nbsp;-&nbsp;Rouge (Martinique)</p>
            <p>
              à <strong>200 mètres du Domaine d'émeraude</strong>
            </p>
          </div>
          <div aria-hidden data-aos="zoom-in"
          >
            <MapWrapper />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
