import React from "react"
import cardStyles from "./card.module.css"
import UtilityIcon from "../assets/icons/utility.svg"
import CleannerIcon from "../assets/icons/cleanner.svg"
import HandCleannerIcon from "../assets/icons/hand-cleanner.svg"

export default ({ title, desc, icon }) => {
  const icons = {
    utility: <UtilityIcon className={cardStyles.icon} />,
    cleanner: <CleannerIcon className={cardStyles.icon} />,
    hand: <HandCleannerIcon className={cardStyles.icon} />,
  }
  return (
    <div className={cardStyles.card}>
      <div className={cardStyles.title}>
        <h3>{title}</h3> {icons[icon]}
      </div>
      <div className={cardStyles.container}>{desc}</div>
    </div>
  )
}
