// https://dev.to/pr0x1ma/building-a-slideshow-with-gatsby-image-and-react-hooks-4ajf
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import slideshowStyles from "./slideshow.module.css"

const SlideShow = () => {
  const [index, setIndex] = useState(0)
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "slides" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  //Minus 1 for array offset from 0
  const length = allFile.edges.length - 1
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
  // const handlePrevious = () =>
  //   index === 0 ? setIndex(length) : setIndex(index - 1)
  const { node } = allFile.edges[index]


  return (
    <div className={slideshowStyles.slideshow} tabIndex={0}>
      <div className={slideshowStyles.images}>
        <Img fluid={node.childImageSharp.fluid} key={node.id} alt="" />
        <div className={slideshowStyles.slideNumber}>
          <span className="sr-only"> photo</span>
          {index + 1}
          <span className="sr-only"> sur {length + 1}</span>
        </div>
      </div>
      <div className={slideshowStyles.actions}>
        <div className={slideshowStyles.slideIndex}>
          <p>{index + 1}</p> / {length + 1}
        </div>
        <div className={slideshowStyles.actionsInner} />
        <button
          type="button"
          onClick={() => handleNext()}
          data-aos="slide-right"
          aria-label="image suivante"
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  )
}
export default SlideShow
