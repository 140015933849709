import React from "react"
import { Map, TileLayer, Marker /*, Popup*/ } from "react-leaflet"

const MapWrapper = () => {
  const position = [14.749768, -61.103564]
  return (
    typeof window !== "undefined" && (
      <Map center={position} zoom={13}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          {/* <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      </Map>
    )
  )
}

export default MapWrapper
