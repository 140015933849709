import React from "react";
import { isMobileOnly } from "react-device-detect"
import Image from "./image"
import styles from "./welcome.module.css"
import SlideShow from "./slideshow";
import CarouselWrapper from "./carouselWrapper"


const Welcome = () =>
    <>
        <div className={styles.welcomeWrapper}>
            <div className={styles.slogan}>
                <p className={styles.line1}>Votre station de lavage sympa</p>
                <p className={styles.horaires}>Car Wash Automate Service</p>
                <p className={styles.horaires}>Ouvert 7 jours / 7 de <strong>6h à 22h</strong></p>
                <p className={styles.desc}>Lavage haute pression</p>
                <p className={styles.desc}> Eau chaude - Cire de brillance</p>
                <div className={styles.icoClean}><Image name="iconCleaning" alt="" /></div>
            </div>
            <div className={styles.heroPhoto}>
                <CarouselWrapper />
            </div>


        </div>
        {
            isMobileOnly && <div className={styles.slides}>
                <SlideShow />
            </div>
        }
    </>

export default Welcome;
